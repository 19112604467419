const binding = { modules: {}, dataActions: {} };

    (binding.modules['footer-item'] = {
        c: () => require('@msdyn365-commerce-modules/footer/dist/lib/modules/footer-item/footer-item'),
        $type: 'moduleDefinition',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'footer-item',
        p: 'footer',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/footer/dist/lib/modules/footer-item'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|newsunshine-b2c|views|footer-item'] = {
                c: () => require('partner/themes/newsunshine-b2c/views/footer-item.view.tsx'),
                cn: '__local__-__local__-footer-item'
            };
viewDictionary['__local__|__local__|themes|newsunshine-dev-b2c|views|footer-item'] = {
                c: () => require('partner/themes/newsunshine-dev-b2c/views/footer-item.view.tsx'),
                cn: '__local__-__local__-footer-item'
            };
viewDictionary['__local__|__local__|themes|newsunshine-dev|views|footer-item'] = {
                c: () => require('partner/themes/newsunshine-dev/views/footer-item.view.tsx'),
                cn: '__local__-__local__-footer-item'
            };
viewDictionary['__local__|__local__|themes|newsunshine|views|footer-item'] = {
                c: () => require('partner/themes/newsunshine/views/footer-item.view.tsx'),
                cn: '__local__-__local__-footer-item'
            };
viewDictionary['@msdyn365-commerce-modules|footer|modules|footer-item|footer-item'] = {
                c: () => require('@msdyn365-commerce-modules/footer/dist/lib/modules/footer-item/footer-item.view.js'),
                cn: '@msdyn365-commerce-modules-footer-footer-item'
            };
viewDictionary['@msdyn365-commerce-theme|adventureworks-theme-kit|modules|adventureworks|views|footer-item'] = {
                c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/views/footer-item.view.js'),
                cn: '@msdyn365-commerce-theme-adventureworks-theme-kit-footer-item'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };